import classNames from 'classnames'

import getConfig from 'next/config'
import CustomImage from 'src/components/Image'
import useI18n from 'src/hooks/use_i18n'
import useResize from 'src/hooks/use_resize'
import Styles from './index.module.scss'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

// #region component
interface Props {
  totalCarriersStr: number | string
}

export default function Banner({ totalCarriersStr }: Props) {
  const { $t, i18nFormatAll } = useI18n()
  const { breakpoint } = useResize()
  const isMobileSize = ['xs', 'sm'].indexOf(breakpoint) > -1
  return (
    <>
      <section className={classNames(Styles['yq-banner'])}>
        <div className={classNames(Styles['yq-carrier-search'])}>
          <div className={classNames(Styles['page-banner-title'])}>
            <h1 id="yq_bannerTile">
              {i18nFormatAll($t('ResWwwPSeo.global.__seoCarriersH1'), { carriersnumber: totalCarriersStr })}
            </h1>
            <strong>
              {i18nFormatAll($t('ResWwwPSeo.global.__seoCarriersSubTitle'), { carriersnumber: totalCarriersStr })}
            </strong>
          </div>
        </div>
        {!isMobileSize && (
          <>
            <CustomImage
              loading="lazy"
              src={`${assetPrefix}/assets/images/carriers/banner`}
              alt="17TRACK"
              width={700}
              height={360}
            />
            <CustomImage priority src={assetPrefix + '/assets/images/carriers/starry_sky'} alt="17TRACK" fill />
          </>
        )}
      </section>
    </>
  )
}
