import classNames from 'classnames'
import getConfig from 'next/config'
import CustomImage from 'src/components/Image'
import useI18n from 'src/hooks/use_i18n'
import Styles from './index.module.scss'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

export default function JoinBanner() {
  const { $t } = useI18n()
  return (
    <>
      <section className={classNames(Styles['page-content'], Styles['yq-panel-banner'])}>
        <div className={classNames(Styles['banner-content'])}>
          <p className={classNames(Styles['banner-text'])}>{$t('ResCarrierPIndex.global.__professional')}</p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              window.location.href = '//features.17track.net/carriersettlein'
            }}
          >
            {$t('ResCarrierPIndex.global.__welcome')}
          </button>
          <div className={Styles.imgContainer}>
            <CustomImage
              loading="lazy"
              src={`${assetPrefix}/assets/images/carriers/join_us_bg`}
              alt="17TRACK"
              height={350}
              width={400}
            />
          </div>
        </div>
      </section>
    </>
  )
}
